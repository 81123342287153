import React, { useEffect, useState } from 'react';
import {Image}from "@chakra-ui/react";
import '../electricity.css'; // Import your CSS file

const ElectricityComponent = () => {
  const [svgPath, setSvgPath] = useState('');

  useEffect(() => {
    const runElectricity = () => {
      let fps = 25,
        now,
        delta,
        then = Date.now(),
        interval = 1000 / fps,
        iteration = 0;

      const calculatePoints = (x, y, width, height) => {
        let points = [[x, height / 2]];
        let maxPoints = 10;
        let chunkRange = width / maxPoints;
        for (let i = 0; i < maxPoints; i++) {
          let cx = chunkRange * i + Math.cos(i) * chunkRange;
          let cy = Math.random() * height;
          points.push([cx, cy]);
        }

        points.push([width, height / 2]);

        let d = points.map((point) => point.join(','));
        return 'M' + d.join(',');
      };

      const render = () => {
        let d = calculatePoints(0, 0, 500, 80);
        setSvgPath(d);
      };

      const loop = () => {
        requestAnimationFrame(loop);

        now = Date.now();
        delta = now - then;
        if (delta > interval) {
          then = now - (delta % interval);

          // update stuff
          render(iteration++);
        }
      };

      loop();
    };

    runElectricity();
  }, []); // Run only once on component mount

  return (
    <div className="electricity">
      <div className="plus-diode">
        <div className="ball">
          <div className="shine"></div>
        </div>
        <div className="socket"></div>
        <div className="socket foot"></div>
        <div className="ring"></div>
        <div className="ring medium"></div>
        <div className="ring small"></div>
      </div>
      <div className="minus-diode">
        <div className="ball">
          <div className="shine"></div>
        </div>
        <div className="socket"></div>
        <div className="socket foot"></div>
        <div className="ring"></div>
        <div className="ring medium"></div>
        <div className="ring small"></div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 200" className="electricity-svg">
        <defs>
          <filter id="f1" x="0" y="0">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
          </filter>
        </defs>
        <g>
          <path d={svgPath} fill="none" stroke="#42ee77" filter="url(#f1)"></path>
          <path d={svgPath} fill="none" stroke="#42ee77"></path>
        </g>
      </svg>
    </div>
  );
};

export default ElectricityComponent;
